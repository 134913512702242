import type { CountryCode } from 'libphonenumber-js'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import type { ParsedQuery } from 'query-string'
import queryString from 'query-string'
import type { ZodError } from 'zod'

import type { Users as User } from '@hasura'
import type { ActionFunction, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useRouteLoaderData } from '@remix-run/react'

export function sleep(duration: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

function isUser(user: any): user is User {
  return user && typeof user === 'object' && typeof user.email === 'string'
}

export function useOptionalUser(): User | undefined {
  const data = useRouteLoaderData('root') as { user?: User }
  if (!data || !isUser(data.user)) {
    return undefined
  }

  return data.user
}

export function useUser(): User {
  const maybeUser = useOptionalUser()
  if (!maybeUser) {
    throw new Error(
      'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.'
    )
  }
  return maybeUser
}

export function validateEmail(email: unknown): email is string {
  return typeof email === 'string' && email.length > 3 && email.includes('@')
}

export function validatePhoneNumber(phone: {
  number?: string
  country?: string
}) {
  if (typeof phone.number !== 'string' || typeof phone.country !== 'string')
    return false
  return isPossiblePhoneNumber(phone.number, phone.country as CountryCode)
}

export function sendHeightPostMessage() {
  const height =
    document.querySelector('footer')?.getBoundingClientRect().bottom ??
    document.querySelector('form')?.getBoundingClientRect().bottom ??
    document.body.scrollHeight

  // console.log(`Setting height to ${height}px`)
  window.parent.postMessage(
    {
      event: 'setHeight',
      height: `${height}px`,
    },
    '*'
  )
  return `Setting height to ${height}px`
}

export function HasuraInsertGuard(fn: ActionFunction): ActionFunction {
  return function (args) {
    if (args.request.method !== 'POST') {
      return json({ message: 'Method not allowed' }, 405)
    }
    if (
      args.request.headers.get('Hasura-Remix-Shared-Secret') !==
      process.env.HASURA_REMIX_SHARED_SECRET
    ) {
      return json({ message: 'Unauthorized' }, 401)
    }
    return fn(args)
  }
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function getSentryDsn() {
  const prod =
    'https://89fbdf3477150a903a95c3c282d5f1e0@o4506136859508736.ingest.sentry.io/4506136859705344'
  // const test =
  //   'https://2793ff4d60354db485745be48e95ab92:b78a3b8ef77f4ddfa336301956656c2a@o54895.ingest.sentry.io/4504581377949696'
  return prod
}

export function getSentryEnvironment(isClient: boolean = false) {
  console.log(
    'getSentryEnvironment',
    `env=${process.env.NODE_ENV}`,
    `remix_host=${process.env.REMIX_HOST}`
  )

  if (isClient && typeof document !== 'undefined') {
    if (window.location.host.includes('localhost')) return 'development'
    // if (NODE_ENV === 'test') return 'test'
    if (window.location.host.includes('staging')) return 'staging'

    return 'production'
  } else {
    const { NODE_ENV } = process.env
    //@ts-expect-error
    if (NODE_ENV === 'local') return 'development'
    if (NODE_ENV === 'development') return 'development'
    if (NODE_ENV === 'test') return 'test'
    //@ts-expect-error
    if (NODE_ENV === 'staging') return 'staging'
    if (NODE_ENV === 'production') {
      if (process.env.REMIX_HOST?.includes('staging')) return 'staging'
      return 'production'
    }
    return 'production'
  }
}

// Check if there is an error for a specific path.
export function errorAtPath(error: ZodError, path: string) {
  return error.issues.find((issue) => issue.path[0] === path)?.message
}

export function isRejected<T>(
  val: PromiseSettledResult<T>
): val is PromiseRejectedResult {
  return val.status === 'rejected'
}

export function isFulfilled<T>(
  val: PromiseSettledResult<T>
): val is PromiseFulfilledResult<T> {
  return val.status === 'fulfilled'
}

function matched<T>(x: T) {
  return {
    on: () => matched(x),
    otherwise: () => x,
  }
}

type Pred<T> = (x: T) => Boolean
type Result<T> = (x: T) => any
export function match<T>(x: T) {
  return {
    on: (pred: Pred<T>, fn: Result<T>) =>
      pred(x) ? matched<T>(fn(x)) : match(x),
    otherwise: (fn: Result<T>) => fn(x),
  }
}

export function getSocialMetas({
  url,
  title = 'Privacy-focused email reminders - Snipbot',
  description = 'Reduce bounce rate with an embeddable reminder-me-later widget for your website visitors.',
  image = 'https://www.snipbot.com/logo.png',
  keywords = '',
}: {
  image?: string
  url: string
  title?: string
  description?: string
  keywords?: string
}): ReturnType<MetaFunction> {
  return [
    { title },
    { description },
    { keywords },
    { image },
    { 'og:url': url },
    { 'og:title': title },
    { 'og:description': description },
    { 'og:image': image },
    { 'og:type': 'website' },
    { 'twitter:card': image ? 'summary_large_image' : 'summary' },
    { 'twitter:creator': '@snipbotapp' },
    { 'twitter:site': '@snipbotapp' },
    { 'twitter:title': title },
    { 'twitter:description': description },
    { 'twitter:image': image },
    { 'twitter:alt': title },
  ]
}

export function host() {
  return match(process.env.NODE_ENV)
    .on(
      //@ts-expect-error
      (e) => e === 'local',
      () => 'http://localhost:3000'
    )
    .on(
      (e) => e === 'development',
      () => 'http://localhost:3000'
    )
    .on(
      //@ts-expect-error
      (e) => e === 'staging',
      () => 'https://staging-remix.fly.dev'
    )
    .on(
      (e) => e === 'production',
      () => 'https://www.snipbot.com'
    )
    .otherwise(() => {
      throw new Error(
        `Unable to find host for environment ${process.env.NODE_ENV}`
      )
    })
}

export function getAppEnv(isClient?: boolean) {
  if (isClient && typeof document !== 'undefined') {
    if (window.location.host.includes('localhost')) return 'development'
    // if (NODE_ENV === 'test') return 'test'
    if (window.location.host.includes('staging')) return 'staging'

    return 'production'
  } else {
    const NODE_ENV = process.env.NODE_ENV
    if (NODE_ENV === 'development') return 'development'
    if (NODE_ENV === 'test') return 'test'
    //@ts-expect-error
    if (NODE_ENV === 'staging') return 'staging'
    if (NODE_ENV === 'production') {
      if (process.env.REMIX_HOST?.includes('staging')) return 'staging'
      return 'production'
    }
    return 'production'
  }
}

/**
 * This will expose all variables within global ENV
 * Only for client-side env
 * Never expose the SESSION_SECRET or any server/node/non-browser env
 * @url https://github.com/mhaidarhanif/rewinds/blob/main/app/utils/env.server.ts
 */
export function getClientEnv() {
  return {
    POSTHOG_API_KEY: process.env.POSTHOG_API_KEY,
    POSTHOG_API_HOST: 'https://app.posthog.com',
    APP_ENV: getAppEnv(true),
    CF_SITE_KEY: process.env.CF_TURNSTILE_SITE_KEY,
  }
}

type ParserFunction = (params: URLSearchParams) => ParsedQuery
export const customParser: ParserFunction = (params) => {
  const parsed = queryString.parse(params.toString())
  return parsed
}

export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
